function form() {

    var prioTaste=[""];
    var prioSpirit=["",""];
    $(".show-field").click(function () {
        $(".custom-package").toggleClass("show");
    });
    $("#newTryBtn").hide();
    $("#prevBtn").hide();

    $("#prevBtn").click(function () {
        $("#nextBtn").removeClass("disable");
        $("#newTryBtn").hide();
        $("#nextBtn").show();
        $("#step-wrap").removeClass("final_step");
        let position=0;
        let steps=document.getElementsByClassName("step");
        let newStep=1;
        for (let index = 0; index < steps.length; index++) {
            const element = steps[index];
            if (element.classList.value=="step active") {
            //if (element.classList.includes("active")) {
                position=index;
                newStep=position-1;
            }
            element.classList.remove("active");
        }
        steps[newStep].classList.add("active");
        if (position==1) {
            $("#prevBtn").hide();
        }
        $(".tab").hide();
        $("#tab-"+newStep).show();
        $("#steps-index").html(newStep+1);
        if ($(window).width() < 580 || $(window).height() < 580) {
            $("html, body").animate(
                {
                    //scrollTop: $("#tastefinder").offset().top,
                    scrollTop: $("#block_cf29d248854165f77f433678d016794c").offset().top,
                },
                { easing: "linear", duration: 150 }
            );
        }
    });
    $("#nextBtn").click(function () {
        let position=0;
        let steps=document.getElementsByClassName("step");
        let newStep=1;
        let continueStep=false;
        for (let index = 0; index < steps.length; index++) {
            const element = steps[index];
            if (element.classList.value=="step active") {
            //if (element.classList.includes("active")) {
                position=index;
                newStep=position+1;
            }
            element.classList.remove("active");
        }
        if (position==0) {
            if (prioTaste[0]=="") {
                steps[position].classList.add("active");
                continueStep=false;
            }
            else
            {
                continueStep=true;
            }
        }
        else
        {
            if (position==1) {
                if (prioSpirit[0]=="") {
                    steps[position].classList.add("active");
                    continueStep=false;
                }
                else
                {
                    continueStep=true;
                }
            }
        }
        if (continueStep) {

            $("#prevBtn").show();

            steps[newStep].classList.add("active");
            if (position==1) {
                $("#nextBtn").hide();
                $("#newTryBtn").show();
                $("#step-wrap").addClass("final_step");
                let filter=prioTaste.concat(prioSpirit);

                //console.log(filter);
                //Ajax
                $.ajax({
                    url: "/wp-content/themes/bergauf/kk-ajax-endpoint.php",
                    method: "POST",
                    data: {
                      type:"tastefinderResult",
                      filter: filter
                    },
                  }).done(function (data) {
                    let posts = JSON.parse(data);
                    //console.log(posts);
                    let productImagesHtml="";
                    let productMatchHtml="";
                    let productRecipesHtml="";
                    let recipeCounter=0;
                    for (let index = 0; index < posts.length; index++) {
                        const element = posts[index];
                        if (index>=4) {
                            break;
                        }
                        //productBilder
                        if (productMatchHtml=="") {
                            productImagesHtml+='<div class="productimage active tastefinder_product_image" id="'+element.post_name+'">';
                        }
                        else
                        {
                            productImagesHtml+='<div class="productimage tastefinder_product_image" id="'+element.post_name+'">';
                        }
                        productImagesHtml+='<picture class="picture">';
                       productImagesHtml+='<img src="'+element.image.guid+'" alt="'+element.image.post_title+'"></img>';
                        productImagesHtml+='</picture>';
                        productImagesHtml+='</div>';
                        //productInfos
                        if (productMatchHtml=="") {
                            productMatchHtml+='<div class="match-content" id="'+element.post_name+'-content">';
                        }
                        else
                        {
                            productMatchHtml+='<div class="match-content" id="'+element.post_name+'-content" style="display:none">';
                        }
                        if (element.preline != undefined) {
                            productMatchHtml+='<div class="preline">';
                            productMatchHtml+=element.preline;
                            productMatchHtml+='</div>';
                        }
                        if (element.headline != undefined) {
                            productMatchHtml+='<div class="headline">';
                            productMatchHtml+='<h2>'+element.headline+'</h2>';
                            productMatchHtml+='</div>';
                        }
                        if (element.description != undefined) {
                            productMatchHtml+='<p>';
                            productMatchHtml+=element.description;
                            productMatchHtml+='</p>';
                        }
                        productMatchHtml+='<div class="button-row">';
                        productMatchHtml+='<a class="btn btn-primary" target="_blank" href="/products/'+element.post_name+'">more details</a>';
                        if (element.shop != undefined) {
                            productMatchHtml+='<a class="btn btn-primary" target="_blank" href="'+element.shop.url+'">buy now</a>';
                        }
                        productMatchHtml+='</div>';
                        productMatchHtml+='</div>';
                        //Rezepte
                        if (productRecipesHtml=="") {
                            productRecipesHtml+='<div class="recipe-idea owl-carousel active" id="'+element.post_name+'-recipes">';}
                        else
                        {
                            productRecipesHtml+='<div class="recipe-idea owl-carousel" id="'+element.post_name+'-recipes">';
                        }
                        for (let indexResults = 0; indexResults < element.results.length; indexResults++) {
                            recipeCounter++;
                            productRecipesHtml+='<div class="recipe-inner" id="'+element.post_name+'-recipe-'+indexResults+'">';
                            const elementResults = element.results[indexResults];
                            if (elementResults.recipe.image!="") {
                                productRecipesHtml+='<div class="image-wrapper">';
                                productRecipesHtml+='<picture>';
                                productRecipesHtml+='<img src="'+elementResults.recipe.image.guid+'" alt="'+elementResults.recipe.image.post_title+'"></img>';
                                productRecipesHtml+='</picture>';
                                productRecipesHtml+='</div>';
                            }
                            productRecipesHtml+='<div class="text-wrapper">';
                            productRecipesHtml+='<div class="headline-wrapper">';
                            productRecipesHtml+='<div class="preline">recipe idea</div>';
                            productRecipesHtml+='<h2>';
                            productRecipesHtml+=elementResults.recipe.headline+'</h2>';
                            if (elementResults.spirit=="agaves") {
                                elementResults.spirit="tequila";
                            }
                            productRecipesHtml+='<div class="headline-tags subline">#'+elementResults.taste+' #'+elementResults.spirit+'</div>';
                            productRecipesHtml+='</div>';
                            productRecipesHtml+='<div class="ingreds">';
                            for (let indexIngredients = 0; indexIngredients < elementResults.recipe.ingredients.length; indexIngredients++) {
                                const elementIngredients = elementResults.recipe.ingredients[indexIngredients];
                                productRecipesHtml+='<div class="ingredient">';
                                productRecipesHtml+=element.check;
                                productRecipesHtml+='<p>'+elementIngredients.ingredient+'</p>';
                                productRecipesHtml+='</div>';
                            }
                            productRecipesHtml+='</div>';
                            productRecipesHtml+='</div>';
                            productRecipesHtml+='</div>';
                        }
                        productRecipesHtml+='</div>';
                    }

                    $('.recipe-idea').trigger('destroy.owl.carousel').removeClass('owl-loaded owl-drag owl-hidden');

                    $('.recipe-idea').find('.owl-stage-outer').children().unwrap();
                    document.getElementById("recipe-wrapper").innerHTML=productRecipesHtml;
                    for (let index = 0; index < posts.length; index++) {
                        const element = posts[index];
                        if (index>=4) {
                            break;
                        }
                        if (element.results.length>1) {
                            $('#'+element.post_name+'-recipes').off('translate.owl.carousel resized.owl.carousel');
                            $('#'+element.post_name+'-recipes').owlCarousel({
                                loop: true,
                                nav: true,
                                lazyLoad: true,
                                autoHeight:true,
                                navText: [
                                    '<span class="owl-carousel-arrow"><img src="/wp-content/themes/bergauf/blocks/tastefinder/form-steps/icons/slider-arrow-left.png" alt="Simple Slider Icon"/></span>',
                                    '<span class="owl-carousel-arrow"><img src="/wp-content/themes/bergauf/blocks/tastefinder/form-steps/icons/slider-arrow-right.png" alt="Simple Slider Icon"/></span>',
                                ],
                                dots: false,
                                responsive: {
                                    0: {
                                        items: 1,
                                    },
                                    768: {
                                        items: 1,
                                    },
                                    1200: {
                                        items: 1,
                                    },
                                },
                            });
                            $('#'+element.post_name+'-recipes').trigger('refresh.owl.carousel');
                            $('#'+element.post_name+'-recipes .owl-next').show();
                            $('#'+element.post_name+'-recipes .owl-prev').show();
                            $('#'+element.post_name+'-recipes').on('translated.owl.carousel resized.owl.carousel', function(e){
                            if ($(this).find(".active").length!=0) {
                                    //console.log($(this).find(".active")[0].childNodes[0]);
                                    let drink=$(this).find(".active")[0].childNodes[0].id.split("-");
                                    //console.log($(this));
                                    let drinkId=drink[0]+"-"+drink[1];
                                    //console.log(drinkId);
                                    $('#result-bottles-images .productimage').removeClass('active');
                                    $("#"+drinkId).addClass("active");
                                }

                            });
                        }
                        else
                        {
                            $('#'+element.post_name+'-recipes').off('translate.owl.carousel resized.owl.carousel');
                            $('#'+element.post_name+'-recipes').owlCarousel({
                                loop: false,
                                nav: false,
                                lazyLoad: true,
                                autoHeight:true,
                                dots: false,
                                responsive: {
                                    0: {
                                        items: 1,
                                    },
                                    768: {
                                        items: 1,
                                    },
                                    1200: {
                                        items: 1,
                                    },
                                },
                            });
                            $('#'+element.post_name+'-recipes').trigger('refresh.owl.carousel');
                            $('#'+element.post_name+'-recipes .owl-next').hide();
                            $('#'+element.post_name+'-recipes .owl-prev').hide();

                        }
                        if (index!=0) {
                            $("#"+element.post_name+"-recipes").addClass("owl-hidden");
                        }
                        //'+element.post_name+'-recipes
                    }

                    $('.recipe-idea').trigger('refresh.owl.carousel');


                    document.getElementById("result-bottles-match").innerHTML=productMatchHtml;
                    document.getElementById("result-bottles-images").innerHTML=productImagesHtml;
                    $(".tastefinder_product_image").on("click", function(){
                        $(".tastefinder_product_image").removeClass("active");
                        $(this).addClass("active");
                        $(".match-content").hide();
                        $("#"+this.id+"-content").show();
                        ////console.log($("#recipe-wrapper"));
                        $('#recipe-wrapper .recipe-idea').removeClass('active');
                        $("#"+this.id+"-recipes").addClass("active");
                        $('#recipe-wrapper .recipe-idea').addClass('owl-hidden');
                        $("#"+this.id+"-recipes").removeClass("owl-hidden");
                        //$('#'+this.id+'-recipe-0').parent().addClass('active');
                        // let counterOwl=0;
                        // for (let index = 0; index < $('#recipe-idea .owl-item').length; index++) {
                        //     const element = $('#recipe-idea .owl-item')[index];

                        //     if (element.classList.contains("cloned")) {
                        //         continue;
                        //     }
                        //     if (element.childNodes[0].id==this.id+'-recipe-0') {
                        //         element.classList.add("active");
                        //         $('#recipe-idea').trigger("to.owl.carousel", [counterOwl, 200, true]);
                        //         break;
                        //     }
                        //     counterOwl++;

                        // }
                        //$('#recipe-idea').trigger('refresh.owl.carousel');
                        //'+element.post_name+'-recipe-'+indexResults+'
                        //$(".recipes_wrapper").hide();
                        // $("#"+this.id+"-recipes").show();

                    });


                  })
                  .fail(function (jqXHR, textStatus) {});

                $("#test").html(prioTaste+" _ "+prioSpirit);
            }
            $(".tab").hide();
            $("#tab-"+newStep).show();
            $("#steps-index").html(newStep+1);

            if ($(window).width() < 580 || $(window).height() < 580) {
            $("html, body").animate(
                {
                    //scrollTop: $("#tastefinder").offset().top,
                    scrollTop: $("#block_cf29d248854165f77f433678d016794c").offset().top,
                },
                { easing: "linear", duration: 150 }
            );
            }
        }
        if ($(window).width() < 580 || $(window).height() < 580) {
            $("html, body").animate(
                {
                    //scrollTop: $("#tastefinder").offset().top,
                    scrollTop: $("#block_cf29d248854165f77f433678d016794c").offset().top,
                },
                { easing: "linear", duration: 150 }
            );
        }

    });
    $("#newTryBtn").on("click", function(){
        prioTaste=[""];
        prioSpirit=["",""];
        $(".tab input[type='checkbox']:checked").prop("checked", false);
        $(".step").removeClass("active");
        document.getElementsByClassName("step")[0].classList.add("active");
        $(".spirits .checkbox").removeClass("inactive");
        $("#step-wrap").removeClass("final_step");
        $(".tastes .checkbox").removeClass("inactive");
        $(".spirits .checkbox").removeClass("active");
        $(".tastes .checkbox").removeClass("active");
        $("#steps-index").html("1");
        $("#tab-0").show();
        $("#tab-2").hide();
        $("#newTryBtn").hide();
        $("#prevBtn").hide();
        $("#nextBtn").show();
        if ($(window).width() < 580 || $(window).height() < 580) {
            $("html, body").animate(
                {
                    //scrollTop: $("#tastefinder").offset().top,
                    scrollTop: $("#block_cf29d248854165f77f433678d016794c").offset().top,
                },
                { easing: "linear", duration: 150 }
            );
        }

    });
    $(".resetBtn").on("click", function(){
        let tab=$(this).parents(".tab")[0].id;
        $("#" + tab + " input[type='checkbox']:checked").prop("checked", false);
        if (tab=="tab-0") {
            prioTaste=[""];
            $(".tastes .checkbox").removeClass("inactive");
            $(".tastes .checkbox").removeClass("active");
        }
        else
        {
            if (tab=="tab-1") {

                $(".spirits .checkbox").removeClass("inactive");
                $(".spirits .checkbox").removeClass("active");
                prioSpirit=["",""];
            }
        }
    });
    var preventdouble=true;
    $(".tab .checkbox").on("click", function (event) {
        if ($(window).width() >= 580 && preventdouble)
        {
            preventdouble=false;
            //event.preventDefault();
            let thisInput=$(this).find("input")[0];
            let position=parseInt($("#steps-index").html())-1;
            let addCheck=false;
            let counter=0;
            if (position==0) {
                $(".tastes .checkbox").removeClass("inactive");
                $(".tastes .checkbox").removeClass("active");
                for (let index = 0; index < prioTaste.length; index++) {
                    const element = prioTaste[index];
                    if (element=="") {
                        addCheck=true;
                        prioTaste[index]=thisInput.id;
                        thisInput.checked=true;
                        counter++;
                        break;
                    }
                    else
                    {
                        counter++;
                        if (element==thisInput.id) {
                            prioTaste.splice(index, 1);
                            prioTaste.push("");
                            thisInput.checked=false;
                            addCheck=true;
                            counter--;
                            break;
                        }
                    }
                }
                ////console.log(prioTaste);
                if (counter==1) {
                    $(".tastes .checkbox").each(function() {
                        let tasteHelper=$(this)[0].classList[1].replace("taste", "");
                        if (!prioTaste.includes(tasteHelper)) {
                            $(this).addClass("inactive");
                        }
                        else
                        {
                            $(this).addClass("active");
                        }
                    });
                }
                else
                {
                    $(".tastes .checkbox").each(function() {
                        let tasteHelper=$(this)[0].classList[1].replace("taste", "");
                        if (prioTaste.includes(tasteHelper)) {
                            $(this).addClass("active");
                        }
                    });
                }
            }
            else
            {
                if (position==1) {
                    $(".spirits .checkbox").removeClass("inactive");
                    $(".spirits .checkbox").removeClass("active");
                    for (let index = 0; index < prioSpirit.length; index++) {
                        const element = prioSpirit[index];
                        if (element=="") {
                            addCheck=true;
                            prioSpirit[index]=thisInput.id;
                            thisInput.checked=true;
                            counter++;
                            break;
                        }
                        else
                        {
                            counter++;
                            if (element==thisInput.id) {
                                prioSpirit.splice(index, 1);
                                prioSpirit.push("");
                                addCheck=true;
                                thisInput.checked=false;
                                counter--;
                                break;
                            }
                        }
                    }
                    ////console.log(prioSpirit);
                    if (counter==2) {
                        $(".spirits .checkbox").each(function() {
                            let spiritHelper=$(this)[0].classList[1].replace("spirit", "");
                            if (!prioSpirit.includes(spiritHelper)) {
                                $(this).addClass("inactive");
                            }
                            else
                            {
                                $(this).addClass("active");
                            }
                        });
                    }
                    else
                    {
                        $(".spirits .checkbox").each(function() {
                            let spiritHelper=$(this)[0].classList[1].replace("spirit", "");
                            if (prioSpirit.includes(spiritHelper)) {
                                $(this).addClass("active");
                            }
                        });
                    }
                }
            }
            if (!addCheck) {
                event.preventDefault();
            }
            else
            {
            }
            setTimeout(function () {
                preventdouble=true;
            ////console.log(thisInput.id);
            }, 100);
        }
        else
        {
            event.preventDefault();
        }
    });
    $(".tab .inner").on("click", function () {
        if ($(window).width() < 580)
        {
            //event.preventDefault();
            let position=parseInt($("#steps-index").html())-1;
            let thisInput=$(this).find("input")[0];
            //console.log(thisInput.id);
            let addCheck=false;
            let counter=0;
            if (position==0) {
                $(".tastes .checkbox").removeClass("inactive");
                $(".tastes .checkbox").removeClass("active");
                for (let index = 0; index < prioTaste.length; index++) {
                    const element = prioTaste[index];
                    if (element=="") {
                        addCheck=true;
                        prioTaste[index]=thisInput.id;
                        counter++;
                        break;
                    }
                    else
                    {
                        counter++;
                        if (element==thisInput.id) {
                            prioTaste.splice(index, 1);
                            prioTaste.push("");
                            addCheck=true;
                            counter--;
                            break;
                        }
                    }
                }
                ////console.log(prioTaste);
                if (counter==1) {
                    $(".tastes .checkbox").each(function() {
                        let tasteHelper=$(this)[0].classList[1].replace("taste", "");
                        if (!prioTaste.includes(tasteHelper)) {
                            $(this).addClass("inactive");
                        }
                        else
                        {
                            $(this).addClass("active");
                        }
                    });
                }
                else
                {
                    $(".tastes .checkbox").each(function() {
                        let tasteHelper=$(this)[0].classList[1].replace("taste", "");
                        if (prioTaste.includes(tasteHelper)) {
                            $(this).addClass("active");
                        }
                    });
                }
            }
            else
            {
                if (position==1) {
                    $(".spirits .checkbox").removeClass("inactive");
                    $(".spirits .checkbox").removeClass("active");
                    for (let index = 0; index < prioSpirit.length; index++) {
                        const element = prioSpirit[index];
                        if (element=="") {
                            addCheck=true;
                            prioSpirit[index]=thisInput.id;
                            counter++;
                            break;
                        }
                        else
                        {
                            counter++;
                            if (element==thisInput.id) {
                                prioSpirit.splice(index, 1);
                                prioSpirit.push("");
                                addCheck=true;
                                counter--;
                                break;
                            }
                        }
                    }
                    ////console.log(prioSpirit);
                    if (counter==2) {
                        $(".spirits .checkbox").each(function() {
                            let spiritHelper=$(this)[0].classList[1].replace("spirit", "");
                            if (!prioSpirit.includes(spiritHelper)) {
                                $(this).addClass("inactive");
                            }
                            else
                            {
                                $(this).addClass("active");
                            }
                        });
                    }
                    else
                    {
                        $(".spirits .checkbox").each(function() {
                            let spiritHelper=$(this)[0].classList[1].replace("spirit", "");
                            if (prioSpirit.includes(spiritHelper)) {
                                $(this).addClass("active");
                            }
                        });
                    }
                }
            }
            if (!addCheck) {
                event.preventDefault();
            }
            else
            {
            }
        }
    });


}

export { form };


import "owl.carousel";
function recipeslide() {
    // $('.recipe-idea').owlCarousel({
    //     loop: true,
    //     nav: true,
    //     lazyLoad: true,
    //     autoHeight:true,
    //     navText: [
    //         '<span class="owl-carousel-arrow"><img src="/wp-content/themes/bergauf/blocks/tastefinder/form-steps/icons/slider-arrow-left.png" alt="Simple Slider Icon"/></span>',
    //         '<span class="owl-carousel-arrow"><img src="/wp-content/themes/bergauf/blocks/tastefinder/form-steps/icons/slider-arrow-right.png" alt="Simple Slider Icon"/></span>',
    //     ],
    //     dots: false,
    //     responsive: {
    //         0: {
    //             items: 1,
    //         },
    //         768: {
    //             items: 1,
    //         },
    //         1200: {
    //             items: 1,
    //         },
    //     },
    // });
}

export { recipeslide };
