function popup() {
  $("#popup-modal").click(function(){
    $("#popup-modal").hide();
  });

  if ($("#popup-modal").length) {
    if (typeof Storage != "undefined") {
      console.log(sessionStorage);
      if (!sessionStorage.getItem("PopupShown")) {
          // add function to play intro
          setTimeout(function() {
            document.getElementById('popup-modal').classList.add('show');
            sessionStorage.setItem("PopupShown", true);
          }, 1500);

      } else{
        $("#popup-modal").hide();
      }
    }
  }
}

export { popup };
