/* General + Third-Party */
import jQuery from 'jquery';
import "owl.carousel";
import { lazyload } from './globals/lazyload';
import { inViewport } from './globals/animation';

/* Layout-Parts */
import { header } from './layout/header';
import { popup } from './layout/popup';

/* Blocks */
import { textslide } from '../../blocks/text-slider-teaser/script';
// import { herovideo } from '../../blocks/hero/script';
import { sliderSimpleOWL } from '../../blocks/product-slider/script';
import { sliderSimpleFancyBoxCaption } from '../../blocks/product-slider/script';
import { scrolldown } from '../../blocks/hero/script';
import { form } from '../../blocks/tastefinder/script';
import { recipeslide } from '../../blocks/tastefinder/script';
import { instaslide } from '../../blocks/auto-instagram-fetch/script';
import { figcaptionHeight } from '../../blocks/auto-instagram-fetch/script';
// import { instasfigcaptionHeightlide } from '../../blocks/auto-instagram-fetch/script';

jQuery(document).ready(_ => {
  // General + Third-Party
  lazyload();
  inViewport();
  //Layout-Parts
  header();
  popup();

  //Blocks
  textslide();
  // herovideo();
  scrolldown();
  sliderSimpleOWL();
  sliderSimpleFancyBoxCaption();
  form();
  recipeslide();
  instaslide();
  figcaptionHeight();
});
