import "@fancyapps/fancybox";
import "owl.carousel";

function sliderSimpleOWL() {
  let owlSliderSimple = jQuery(".product-slider");
  if (owlSliderSimple.length!=0) {
    //var itemCount = jQuery(owl).find('.stage').length;

  owlSliderSimple.owlCarousel({
    animateOut: 'fadeOut',
    nav: true,
    navText: [
      '<span class="owl-carousel-arrow"><img src="/wp-content/themes/bergauf/blocks/product-slider/icons/slider-arrow-left.png" alt="Prev"/></span>',
      '<span class="owl-carousel-arrow"><img src="/wp-content/themes/bergauf/blocks/product-slider/icons/slider-arrow-right.png" alt="Next"/></span>',
    ],
    loop: true,
    center: true,
    items: 7,
    lazyLoad: true,
    lazyLoadEager: 2,
    autoWidth: false,
    dots: false,
    autoplay: false,
    /*
    autoplayHoverPause: true,
    autoplaySpeed: 250,
    */
    mouseDrag: false,
    touchDrag: false,
    navContainer: '#nav-custom',
    onInitialized: function(event) {
      let element = jQuery(event.target);
      let idx = event.item.index;
      //console.log(idx);

      element.find('.owl-item.left').removeClass('left');

      element.find('.owl-item.second').removeClass('second');
      element.find('.owl-item').eq(idx + 1).addClass('second');
      element.find('.owl-item').eq(idx - 1).addClass('second left');

      element.find('.owl-item.third').removeClass('third');
      element.find('.owl-item').eq(idx + 2).addClass('third');
      element.find('.owl-item').eq(idx - 2).addClass('third left');

      element.find('.owl-item.fourth').removeClass('fourth');
      element.find('.owl-item').eq(idx + 3).addClass('fourth');
      element.find('.owl-item').eq(idx - 3).addClass('fourth left');
    },
    responsive: {
      0: {
        items: 1,
      },
      650: {
        items: 3,
      },
      992: {
        items: 5,
      },
      1440: {
        items: 7,
      },
    },
  });
  var rect = jQuery(".owl-carousel")[0].getBoundingClientRect();
  //console.log(rect);
  // owlSliderSimple.on('initialized.owl.carousel translate.owl.carousel resize.owl.carousel', function(e){
  owlSliderSimple.on('translate.owl.carousel resized.owl.carousel', function(e){
    let element = jQuery(e.target);
    let idx = e.item.index;

      // console.log(rect);
      // console.log($(window).height());
      // if (
      //     rect.top >= 0 &&
      //     rect.left >= 0 &&
      //     // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      //     // rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
      //     rect.bottom <= $(window).height() && /* or $(window).height() */
      //     rect.right <= $(window).width() /* or $(window).width() */
      // )
      // {
      //   console.log(idx);
      //   idx=6;
      //   e.item.index=6;
      // };

      element.find('.owl-item.left').removeClass('left');

      element.find('.owl-item.second').removeClass('second');
      element.find('.owl-item').eq(idx + 1).addClass('second');
      element.find('.owl-item').eq(idx - 1).addClass('second left');

      element.find('.owl-item.third').removeClass('third');
      element.find('.owl-item').eq(idx + 2).addClass('third');
      element.find('.owl-item').eq(idx - 2).addClass('third left');

      element.find('.owl-item.fourth').removeClass('fourth');
      element.find('.owl-item').eq(idx + 3).addClass('fourth');
      element.find('.owl-item').eq(idx - 3).addClass('fourth left');

      // console.log(idx);
  });
  let lastKnownScrollPosition = 0;
  let ticking = false;
  let owlChange = true;

  //wieder einkommentieren wenn mit styling durch
  function doSomething(scrollPos, owlChange) {
    var rect = jQuery(".owl-carousel")[0].getBoundingClientRect();
    if (rect.bottom < 0 && rect.top < 0) {
      if (owlChange) {
        //owlSliderSimple.trigger('stop.owl.autoplay');
        owlSliderSimple.trigger('to.owl.carousel', [0, 1, true]);
        owlChange=false;
      }
    }
    else
    {
      //owlSliderSimple.trigger('play.owl.autoplay');
      owlChange=true;
    }
    return owlChange;
  }

  document.addEventListener("scroll", (event) => {
    lastKnownScrollPosition = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        owlChange=doSomething(lastKnownScrollPosition, owlChange);
        ticking = false;
      });

      ticking = true;
    }
  });
  }

}

export { sliderSimpleOWL };


function sliderSimpleFancyBoxCaption() {
  $( '.slider-simple-fancybox' ).fancybox({
    selector : '.slider-simple-item:visible > a',
    infobar : false,
    toolbar: false,
    arrows: false,
    caption : function( instance, item ) {
      var caption = $(this).data('caption') || '';
      return (caption)
    }
  });
}

export { sliderSimpleFancyBoxCaption };
