import "owl.carousel";
function textslide() {
  $('.textslide').owlCarousel({
    loop: true,
    nav: true,
    navText: [
      '<span class="owl-carousel-arrow"><img src="/wp-content/themes/bergauf/blocks/text-slider-teaser/icons/slider-arrow-left.png" alt="Simple Slider Icon"/></span>',
      '<span class="owl-carousel-arrow"><img src="/wp-content/themes/bergauf/blocks/text-slider-teaser/icons/slider-arrow-right.png" alt="Simple Slider Icon"/></span>',
    ],
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  });
}

export { textslide };
