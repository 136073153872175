import "owl.carousel";
function instaslide() {
  $('.instagram-slider').owlCarousel({
    loop: true,
    nav: true,
    lazyLoad: true,
    lazyLoadEager: 2,
    // autoHeight: true,
    navText: [
      '<span class="owl-carousel-arrow"><img src="/wp-content/themes/bergauf/blocks/auto-instagram-fetch/icons/slider-arrow-left.png" alt="Simple Slider Icon"/></span>',
      '<span class="owl-carousel-arrow"><img src="/wp-content/themes/bergauf/blocks/auto-instagram-fetch/icons/slider-arrow-right.png" alt="Simple Slider Icon"/></span>',
    ],
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  });
}

export { instaslide };


function figcaptionHeight() {
  //setTimeout(function () {
    var highestBox = 0;
        $('.slider ').each(function () {
          $('.textwrapper figcaption', this).each(function () {
              if ($(this).height() > highestBox) {
                  highestBox = $(this).height();
              }
          });
          console.log(highestBox);
          $('.textwrapper figcaption', this).height(highestBox);
      });
  //}, 1000);
}

export { figcaptionHeight };
