function header() {

  //Add Class by open Menu
  $('header .navbar-toggler').click(function (event) {
    event.preventDefault();
    $('html').toggleClass('open-nav');
  });

  $('.navbar-toggler').click(function (event) {
    event.preventDefault();
    $(this).toggleClass("open");
    $('#main-menu').toggleClass("open");
  });

  function stickyHeader(){
    var st = $(window).scrollTop();
    if ( st > 0 ) {
      $('header').addClass('sticky');
    } else {
      $('header').removeClass('sticky');
    }
  }

  stickyHeader();

  $(window).scroll(function(){

    stickyHeader();

  });

  $(document).on('click', function (e) {
    if (!$('#main-menu li.children > a').is(e.target)
      && $('#main-menu li.children > a').has(e.target).length === 0
      && $('#main-menu li.children.show').has(e.target).length === 0
    ) {
      $('#main-menu li.children > a').parent('.children').removeClass('show');
      $('nav').removeClass("show");
    }
});

if (screen.width >= 992) {
  // Dropdown show
  $('#main-menu li.children > a[href="#"], #main-menu li.children > button').on("click", function (e) {
    if ($(this).parents('nav').hasClass("show")) {
      $(this).parent('.children').siblings('.children').removeClass("show");
      $(this).parent('.children').removeClass("show");
      $(this).parents('nav').removeClass("show");
    }
    else
    {
      $(this).parent('.children').siblings('.children').removeClass("show");
      $(this).parent('.children').addClass("show");
      $(this).parents('nav').addClass("show");
    }

    e.stopPropagation();
    e.preventDefault();
  });

  // $(window).on('scroll', function (event) {
  //     var $target = $(event.target);

  //     if ($('#main-menu .children.show').length > 0) {
  //         if ($target.parents('.children').length === 0) {
  //           $('#main-menu .children').removeClass('show');
  //           $('nav').removeClass("show");
  //         }
  //     }
  // });

  // show backdrop on hover
  // $('#main-menu li.children').hover( function(e){
  //   // console.log(e);
  //   $(this).addClass('show');
  //   $(this).siblings().removeClass('show');
  //   $(this).parents('nav').addClass('show');
  //   $('html').addClass('open-nav');
  // });

  $('#main-menu li.children').click( function(e){
    //e.preventDefault();

      $(this).addClass('show');
      $(this).siblings().removeClass('show');
      $(this).parents('nav').addClass('show');
      $('html').addClass('open-nav');

  });

  // $('nav, nav .dropdown-wrapper').mouseleave(function(){
  //   $(this).find('li.children.show').removeClass('show');
  //   $(this).parents('nav').removeClass('show');
  //   $('html').removeClass('open-nav');
  // });

  // $(document).hover( function(e){
  //   console.log(e);
  //   if (!$('nav, nav .dropdown-wrapper').is(e.target)
  //     && $('nav').has(e.target).length === 0
  //     && $('nav, nav .dropdown-wrapper').has(e.target).length === 0
  //   ) {
  //     $('#main-menu li.children').removeClass('show');
  //     $('nav').removeClass("show");
  //   }
  // });
} else{
  // Dropdown show
  $('#main-menu li.children > a[href="#"], #main-menu li.children > button').on("click", function (e) {
    $(this).parent('.children').siblings('.children').removeClass("show");
    $(this).parent('.children').toggleClass("show");
    $(this).parents('nav').toggleClass("show");
    e.stopPropagation();
    e.preventDefault();
  });
}


}
export { header };

function mobileMetaNav() {
  if (screen.width < 1200) {
    console.log('clone');
    $('nav.navbar-meta .navbar-meta-inner').clone().appendTo('.navbar-meta-mobil');
  }
}

export {mobileMetaNav};
